import React, { Component } from "react"
import './MemberPost.less';
import {graphql} from "gatsby";
import { Row, Col } from 'antd';
import Wrapper from '../components/Wrapper.js';
import BrewMug from '../components/BrewMug';
import Img from "gatsby-image";
import {Icon} from "react-fa";
import GoBack from "../components/GoBack";
import Link from "../components/Link";

class MemberPost extends Component {
    render() {
        const member = this.props.data.wordpressWpTeamMember;
        const acfFields = member.acf;
        let image = '';
        if(acfFields.image) image = acfFields.image.localFile.childImageSharp.original.src;

        // Link up any social networks
        let socialNetworks = [];
        if(member.acf.linked_in_url) socialNetworks.push(member.acf.linked_in_url);
        if(member.acf.twitter_url) socialNetworks.push(member.acf.twitter_url);

        let schema = [
            {
                "@context": "https://schema.org",
                "@type": "Person",
                image: "https://www.wearebrew.co.uk" + image,
                jobTitle: acfFields.job_title,
                name: acfFields.meta_title,
                email: acfFields.email_address,
                telephone: acfFields.telephone_number,
                url: `https://www.wearebrew.co.uk/who-we-are/${member.slug}/`,
                sameAs : socialNetworks
            }
        ];

        return (
            <Wrapper
                title={member.acf.meta_title || member.title}
                description={member.acf.meta_description}
                og_title={acfFields.og_title || member.acf.meta_title || member.title}
                og_description={acfFields.og_description || member.acf.meta_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                schema={schema}
                path={`/who-we-are/${member.slug}/`}
            >
                <span className="team-back">
                    <GoBack />
                </span>
                <div className="team-bio">
                    <Row>
                        <Col xs={{span: 24}} md={{span: 12, push: 12}}>
                            <Img  style={{width: 620, height: 620}} fluid={member.acf.image.localFile.childImageSharp.fluid} />
                        </Col>
                        <Col xs={{span: 24}} md={{span: 12, pull: 12}}>
                            <h1>{member.title}</h1>
                            <p className="job-title">{member.acf.job_title}</p>
                            <div className="contact">

                                { member.acf.linked_in_url !== '' ?
                                    <a className="linkedin" href={"https://www.linkedin.com/" + member.acf.linked_in_url} target="_blank" rel="noopener noreferrer"><Icon name="linkedin" />Join {member.title.substr(0, member.title.indexOf(' '))} on LinkedIn</a>
                                : null }

                                { member.acf.twitter_handle !== '' ?
                                    <a className="twitter" href={"https://www.twitter.com/" + member.acf.twitter_handle} target="_blank" rel="noopener noreferrer"><Icon name="twitter" />Follow {member.title.substr(0, member.title.indexOf(' '))} on Twitter </a>
                                : null }

                            </div>
                            <p className="bio" dangerouslySetInnerHTML={{__html: member.acf.bio }} />
                        </Col>
                    </Row>
                </div>
                <div className="build-a-brew">
                    <Row>
                        <h2>What's my brew</h2>
                        <span className="mug" >
                            <BrewMug />
                        </span>
                        <div className="parts">
                            {member.acf.build_a_brew && member.acf.build_a_brew.map((brew) => {
                                return (
                                    <div key={brew.image.id} className="part">
                                        <img src={brew.image.localFile.childImageSharp.original.src} alt="My perfect Brew" />
                                        <span>{brew.text}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </Row>
                </div>
                { member.acf.my_featured_project != null ?
                    <div className="case-study-item" style={{backgroundColor: member.acf.my_featured_project.acf.project_colour}}>
                        <Row className="big-row">
                            <Col xs={{span: 24}}>
                                <h2>Favourite project</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span:24}} md={{span:14}}>
                                <h3>{member.acf.my_featured_project.acf.project_strapline}</h3>
                            </Col>
                            <Col xs={{span: 24}} md={{span:10}}>
                                <p className="services">{member.acf.my_featured_project.acf.services && member.acf.my_featured_project.acf.services.map((service) => {
                                    return <span key={'mpfpst-key-'+service.value}>{service.label}</span>
                                })}</p>
                                <div className="project-image">
                                    <Img fluid={member.acf.my_featured_project.acf.project_image.localFile.childImageSharp.fluid} />
                                </div>
                                <Link to={"/our-work/"+member.acf.my_featured_project.post_name} className="more">Read More</Link>
                            </Col>
                        </Row>
                        <Row className="big-row">
                            <Col xs={{span: 24}}>
                                <Link to="/our-work/" className="arrow-link">All projects</Link>
                            </Col>
                        </Row>
                    </div>
                : null }
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressWpTeamMember(id: { eq: $id }) {
            id
            slug
            title
            acf {
                meta_title
                meta_description
                job_title
                image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 650) {
                                ... GatsbyImageSharpFluid
                            }
                            original {
                                  width
                                  height
                                  src
                            }                            
                        }
                    }
                }
                email_address
                telephone_number
                linked_in_url
                twitter_handle
                bio
                build_a_brew {
                    image {
                    id
                        localFile {
                            childImageSharp {
                                original {
                                      width
                                      height
                                      src
                                }
                                fluid {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }  
                    }
                    text
                }
                
            }
        }
    }`

export default MemberPost


// my_featured_project {
//     post_name
//     acf {
//         project_strapline
//         project_image {
//             localFile {
//                 childImageSharp {
//                     fluid(maxHeight: 400) {
//                     ... GatsbyImageSharpFluid
//                     }
//                 }
//             }
//         }
//         project_colour
//         services {
//             value
//             label
//         }
//     }
// }
